import styled from 'styled-components'
import { themeGet } from '@style'

export default styled.p`
  color: hsl(0, 0%, 10%);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: ${themeGet('space.4')};
  margin-top: ${themeGet('space.3')};
`
