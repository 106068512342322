import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Box } from '@components/Grid'
import Headline from '@components/Headline'
import Icon from '@components/Icon'
import Button from '@components/Button'

import Card from './Card'
import Text from './Text'
import Description from './Description'

const Statement = () => {
  const { t } = useTranslation()

  const statementTexts = {
    headline: t('STATEMENT_HEADLINE'),
    text: t('STATEMENT_TEXT'),
    items: t('STATEMENT_ITEMS', { returnObjects: true }).map(x => ({
      icon: 'Check',
      description: x,
    }))
  }
  const { headline, text, items } = statementTexts

  return (
    <Card>
      <Headline>{headline}</Headline>
      <Text>{text}</Text>

      <div id="products">
        {items.map((item) => (
          <Flex
            flexDirection={['column', 'row']}
            key={item.description}
            width="100%"
            pb={4}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Flex
              width={['100%', '10%']}
              justifyContent={['center', 'flex-start']}
              pb={[3, 1]}
            >
              <Icon color="saddleBrown" name={item.icon} />
            </Flex>
            <Flex width={['100%', '90%']} justifyContent="flex-start">
              <Description>{item.description}</Description>
            </Flex>
          </Flex>
        ))}
      </div>

      <Flex width="100%" justifyContent="center">
        <Box flex="0 1 260px">
          <Button primary href="https://ofspots.com/whitelist.html">
            {t('STATEMENT_CTA')}
          </Button>
        </Box>
      </Flex>
    </Card>
  )
}

export default Statement
