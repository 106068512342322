import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useTransition, animated } from 'react-spring'
import styled from 'styled-components'

const TransitionItem = styled(animated.div)`
overflow: hidden;
  width: 100%;
  color: white;
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  justify-content: center;
`
const IntroAnimation = () => {
  const ref = useRef([])
  const [items, set] = useState([])
  const transitions = useTransition(items, null, {
    from: { opacity: 0, height: 0, innerHeight: 0, transform: 'perspective(600px) rotateX(0deg)', color: '#2d3748' },
    enter: [
      { opacity: 1, height: 40, innerHeight: 40 },
      { transform: 'perspective(600px) rotateX(180deg)', color: '#28d79f' },
      { transform: 'perspective(600px) rotateX(0deg)' },
    ],
    leave: [{ color: '#2d3748' }, { innerHeight: 0 }, { opacity: 0, height: 0 }],
    update: { color: '#feb2b2' },
  })

  const reset = useCallback(() => {
    ref.current.map(clearTimeout)
    ref.current = []
    set([])
    ref.current.push(setTimeout(() => set(['Wir', 'Lieben', 'Nähen']), 2000))
    ref.current.push(setTimeout(() => set(['Wir', 'Lieben']), 5000))
    ref.current.push(setTimeout(() => set(['Wir', 'Lieben', 'Stoffe']), 8000))
    ref.current.push(setTimeout(() => set(['Wir', 'Lieben']), 10000))
    ref.current.push(setTimeout(() => set(['Wir', 'Lieben', 'Schnittmuster']), 12000))
  }, [])

  useEffect(() => void reset(), [])

  return (
    <div>
      {transitions.map(({ item, props: { innerHeight, ...rest }, key }) => (
        <TransitionItem key={key} style={rest} onClick={reset}>
          <animated.div style={{ overflow: 'hidden', height: innerHeight }}>{item}</animated.div>
        </TransitionItem>
      ))}
    </div>
  )
}

export default IntroAnimation