import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Amplify from 'aws-amplify'
import TextLoop from "react-text-loop"

import config from '../aws-exports'
import Button from '@components/Button'
import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { Flex, Box } from '@components/Grid'
import { Text } from '@components/Text'
import Banner from '@components/Header/Banner'
import Section from '@components/Section'
import IntroAnimation from '@components/Animation'
import { Mark, RainbowText } from '@components/Typography'
import { Heighlight } from '@components/Heighlight'
import ComingSoon from '@components/ComingSoon'
import Statement from '@components/Statement'

import MarkupContainer from '../templates/Container'

import { withI18next } from '../i18n'

Amplify.configure(config)

const Pattern = styled.div`
  width: 100%;
  height: 100%;
  min-height: 120px;
  
  background-color: #fff;
  opacity: 1;
  background-image: radial-gradient(#2d3748 2px, #fff 2px);
  background-size: 40px 40px;
`
const Pattern2 = styled.div`
background-color: #fff;
background-image:  radial-gradient(#813405 2px, transparent 2px), radial-gradient(#813405 2px, #fff 2px);
background-size: 80px 80px;
background-position: 0 0,40px 40px;
`

const CallToAction = styled(Button)`
`

const IndexPage = ({ data }) => {
  const { t } = useTranslation()
  const creativeImage = getImage(data.creativeImage)
  const mapImage = getImage(data.mapImage)
  const quadImage = getImage(data.quadImage)

  return (
    <Layout>
      <SEO
        title="Jederzeit genau das passende Gelände finden"
        pathname="/"
        description="Such dir dein nächstes Quad Abenteuer"
      />

      <Banner >
        {t('BANNER_TEXT')}
      </Banner>

      <Section bg="peach" withBackground>
        <Flex justifyContent="center" px={[3, 3, 0]}>
          <Box py={6}>
            <Flex flexDirection="column">
              <RainbowText fontSize={[2, 4]}>
                {t('HOME_TOPLINE')}
              </RainbowText>
                <Text fontSize={[3, 7]}>
                  <Flex flexDirection={["column", "column", "column", "row"]}>
                    <Mark >{t('HOME_HERO_HEADLINE_1')}</Mark>
                    <Box px={[0, 0, 0, 3]}>
                    <TextLoop>
                      {t('HOME_HERO_TEXTLOOP', { returnObjects: true }).map(item => (
                      <Mark>{item}</Mark>
                      ))}
                    </TextLoop>
                    </Box>
                  </Flex>
                </Text>
                <Text fontSize={[3, 7]}>
                  <Mark fontSize={[3, 7]}>{t('HOME_HERO_HEADLINE_2')}</Mark>
                </Text>

              <Flex mt={3}>
                <CallToAction href="https://ofspots.com/whitelist.html">{t('HOME_HERO_CTA')}</CallToAction>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Section>
      <Section>
        <Flex flexDirection={['column', 'row']}>
          <Box width={[1, "40%"]}>
          <Pattern>
            <Flex justifyContent="center" flexDirection="column" height="100%" p={3}>
              <Box pl={[2, 3]}>
                <Text fontSize={[4, 5, 6]}>
                  {/* OFSPOTS */}
                </Text>
                <Text>
                  {/* Find your next ATV adventure */}
                </Text>
              </Box>
            </Flex>
            </Pattern>
          </Box>
          <Box width={[1, "60%"]}>
            <GatsbyImage image={creativeImage} />
          </Box>
        </Flex>
      </Section>

      <Section>
        <Flex px={[1, 1, 3]} py={1, 1, 5} justifyContent="center">
          <Text fontSize={5}>
            <MarkupContainer>
              <div dangerouslySetInnerHTML={{__html: t('CUSTOMER_PROOF')}}></div>
            </MarkupContainer>
          </Text>
        </Flex>
      </Section>

      <Section bg="light">
        <ComingSoon />
      </Section>
      <Section>
        <Flex flexDirection={['column', 'row']} alignItems="center">
          <Box width={[1, "40%"]}>
              <GatsbyImage image={quadImage} />
          </Box>
          <Box width={[1, "60%"]}>
            <Text fontSize={5} textAlign="center">
              <MarkupContainer>
                <div dangerouslySetInnerHTML={{__html: t('BENEFIT_1')}}></div>
              </MarkupContainer>
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="center" mt={4}>
          <Button primary href="/contact" href="https://ofspots.com/whitelist.html">{t('HOME_HERO_CTA')}</Button>
        </Flex>
      </Section>

      <Section bg="grayDark">
        <Box py={5} mt={5}>
        <Statement />
        </Box>
      </Section>
      <Section bg="grayDark">
        <Flex px={[5, 5, 7]} py={[1, 1, 5]} justifyContent="center">
          <RainbowText fontSize={5}>
            {t('BENEFIT_2')}
          </RainbowText>
        </Flex>
        <Flex justifyContent="center"mb={6} pb={4}>
        {/* <Button href="/contact">{t('HOME_HERO_CTA')}</Button> */}
        </Flex>
      </Section>
      <Section>
        <Flex px={[1, 1, 3]} py={1, 1, 5} justifyContent="center">
          <Text fontSize={8} color="grayDark">"</Text>
          <Text fontSize={5}>
            <MarkupContainer>
              <div dangerouslySetInnerHTML={{__html: t('HOME_FINAL_WORDS')}}></div>
            </MarkupContainer>
          </Text>
        </Flex>
        <GatsbyImage image={mapImage} />
      </Section>
    </Layout>
  )
}

IndexPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}
export default withI18next()(IndexPage)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }

    creativeImage: file(
      relativePath: { eq: "undraw_navigator_a479.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mapImage: file(
      relativePath: { eq: "undraw_Destination_re_sr74.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    quadImage: file(
      relativePath: { eq: "quad-art-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
