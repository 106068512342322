import styled from 'styled-components'
import { themeGet } from '@style'

export default styled.p`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${themeGet('colors.flame')};
`
