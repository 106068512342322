import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Flex, Box } from '@components/Grid'
import HeadlineLabel from './HeadlineLabel'
import Headline from './Headline'
import Info from './Info'
import Feature from './Feature'

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 4em;
  margin-bottom: 2em;
  width: 80%;
`

const ComingSoon = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Flex justifyContent="center" flexDirection="column" py={4}>
        <HeadlineLabel>{t('COMINGSOON_TOPLINE')}</HeadlineLabel>
        <Headline>
        {t('COMINGSOON_HEADLINE')}        
        </Headline>

        <Info>
          {t('COMINGSOON_INFO')}
        </Info>

        <Flex flexWrap="wrap">
         <Box width={['100%', '50%']} mb={4}>
            <Feature
              headline="Spots"
              description="Du suchst neue coole Plätze in deiner nähe?"
              icon="Check"
            />
          </Box>          <Box width={['100%', '50%']} mb={4}>
            <Feature
              headline="Connect"
              description="Du suchst Quad begeisterte in deiner nähe?"
              icon="Check"
            />
          </Box>
          <Box width={['100%', '50%']} mb={4}>
            <Feature
              headline="Touren"
              description="Du suchst Quad Touren?"
              icon="Check"
            />
          </Box>
          <Box width={['100%', '50%']} mb={4}>
            <Feature
              headline="News"
              description="Dir fehlen News und Veranstaltungen?"
              icon="Check"
            />
          </Box>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

export default ComingSoon
