import styled from 'styled-components'
import { themeGet } from '@style'

import { Text } from '../../Text'

const RainbowText = styled(Text)`
  background-color: #CA4246;
  // background-image: linear-gradient(268.67deg, rgb(255, 255, 255) 3.43%, rgb(255, 240, 102) 15.69%, rgb(255, 163, 26) 55.54%, rgb(255, 0, 115) 99%);

  background-image: linear-gradient(
    45deg,
    #D45113 16.666%, 
    #E16541 16.666%, 
    #E16541 33.333%, 
    #F18F43 33.333%, 
    #F18F43 50%, 
    #8B9862 50%, 
    #8B9862 66.666%, 
    #476098 66.666%, 
    #476098 83.333%, 
    #A7489B 83.333%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export default RainbowText

