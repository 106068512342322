import styled from 'styled-components'
import { themeGet } from '@style'

export default styled.h2`
  color: ${themeGet('colors.saddleBrown')};
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  text-decoration: none;
  `
