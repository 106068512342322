import styled from 'styled-components'
import { themeGet } from '@style'
import { Text } from '../../Text'

const Mark = styled(Text)`
  color: ${themeGet('colors.saddleBrown')};
  font-weight: 700;
`

export default Mark
