import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeGet } from '@style'

import { Flex } from '@components/Grid'
import Icon from '@components/Icon'

const Title = styled.p`
  margin: 0;
  padding: 0;
  color: #222;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: ${themeGet('space.1')};
`

const Description = styled.p`
  margin: 0;
  padding: 0;
  color: #666;
  font-size: 16px;
  line-height: 1.5;
`

const Feature = (props) => {
  const { headline, icon, description } = props
  return (
    <Flex w="100%" pr={2}>
      <Flex pr={2} flex="0 0 60px" alignItems="center" justifyContent="center">
        <Icon name={icon} color="saddleBrown" />
      </Flex>
      <Flex flex="1 1 auto" flexDirection="column">
        <Title>{headline}</Title>
        <Description>{description}</Description>
      </Flex>
    </Flex>
  )
}

Feature.propTypes = {
  headline: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
export default Feature
