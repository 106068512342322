import styled from 'styled-components'

import Card from '@components/Card'

const cardBreakpoint = '550px'
export default styled(Card)`
  max-width: ${cardBreakpoint};
  margin: 0 auto;
  border-radius: 0;

  @media (min-width: ${cardBreakpoint}) {
    border-radius: 10px;
  }
`
