/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "ofspots-20210510134033-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://ofspots-20210510134033-hostingbucket-dev.s3-website.eu-central-1.amazonaws.com"
};


export default awsmobile;
