import styled from 'styled-components'
import { themeGet } from '@style'

const Description = styled.div`
  text-align: center;
  color: hsl(0, 0%, 40%);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0 auto;
  margin-bottom: ${themeGet('space.4')};
  margin-top: ${themeGet('space.2')};
  max-width: 700px;
`

export default Description
